import React from 'react';
import '../App.css';
import Cancel from './cancel.png'

export const Popup = (props) => {
    return(
        <div className="overlay">
            <div className="Popup">
                <div className="close-container">
                    <img className="close" src={Cancel} onClick={props.closeFunction} alt="close button"/>
                </div>
                <div className="login-container">
                    <h2 style={{fontFamily: "logoFont", fontSize: "36px"}}>Login</h2>
                    <input className="input-login" placeholder="Username"></input>
                    <input className="input-login" placeholder="Password"></input>
                    <button className="login-button">Login</button>
                </div>
                
                
                
                
            </div>
        </div>
    );
}