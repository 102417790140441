import React from 'react';
import '../App.css';
import exitImage from './exit_white.png';

export const SideBar = (props) => {
    return (
       <div style={{gridArea: "sidebar"}}>
            <div className="side-bar">
                <div class="SideBar-Login-Container">
                    <span class="SideBar-Login-Button" onClick={props.handleLogin}>Login<br></br><p style={{fontSize:"13px",fontFamily:"none"}}>(UNDER CONSTRUCTION)</p></span>
                </div>
                <div class="SideBar-Home-Container">
                    <span class="SideBar-Home-Button" onClick={props.handleButton}>Home</span>
                </div>
                
            </div>
            
        <div className="side-bar-exit">
            <img className="sidebar-exit-image" src={exitImage} alt="exit-button"></img>
        </div>
            
        </div> 
        
    );

}
