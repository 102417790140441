import '../App.css';
import React from 'react';

export const SearchButton = (props) => {
    return (
        <button onClick={props.handleButton} className="search-button">{<img className="button-image" src={props.myImage} alt="arrow"/>}</button>
    );

}

