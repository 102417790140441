import './App.css';
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import myImage from './arrow.png';
import { HomeButton } from './components/HomeButton';
import { ResponseOutput } from './components/ResponseOutput';
import { SearchBar } from './components/SearchBar';
import { SearchButton } from './components/SearchButton';
import { SourceOutput } from './components/SourceOutput';
import { SideBar } from './components/SideBar';
import { SideBarButton } from './components/SideBarButton';
import { Popup } from './components/Popup';




const socket = io(process.env.REACT_APP_BACKEND_SERVER, { withCredentials: true });



function App() {
  
  const [inputValue, setInputValue] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [responseState, setResponseState] = useState(false);
  const [formattedText, setFormattedText] = useState('');
  const [sourceNameVar, setSourceNameVar] = useState("");
  const [sourceLinkVar, setSourceLinkVar] = useState("");
  const [searched, setSearched] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  
  
  

  

  useEffect(() => {
    function processText(text) {
      // Regular expression to capture 'Source:' and 'Source_Link:' followed by any characters until an asterisk
      const sourcePattern = /(Source|Source_Link):\s*([^*]+)\*/g;
      
      let newText = text.replace(sourcePattern, (match, prefix, capturedText) => {
        if (prefix === 'Source') {
          setSourceNameVar(capturedText); // Assuming setSourceNameVar is a function to set state for source
        } else if (prefix === 'Source_Link') {
          setSourceLinkVar(capturedText); // Assuming setSourceLinkVar is a function to set state for source link
        }
        return '\n \n \n '; // Replacing with three new lines
      });
    
      setResponseState(true);
      setFormattedText(newText);
    }
    socket.on('gptResponse', data => {
      processText(data);
   
    });

    socket.on('error', errorMessage => {
      console.error('Error:', errorMessage);
    });

    // Clean up the effect
    return () => socket.off('gptResponse').off('error');
  }, []);

  const sendPrompt = () => {
    socket.emit('gptRequest', { inputValue });
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });
  

  function handleInputChange(event) {
    setInputValue('  ' + (event.target.value).trimStart());
    
  }

  function resetInput(event) {
    setInputValue(' ');
  }

  function handleButton() {
    setSearched(true);
    sendPrompt();
    resetInput();
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleButton();
      // Add any action here
    }
  }

  const Home_Button = (event) => {
    setSearched(false);
    const e = event;
    setResponseState(false);
    setShowSearchBar(true);
    resetInput(e);
    console.log("all functions ran");
    
  }

  const HandleSideBar = () => {
   showSideBar ? setShowSideBar(false) : setShowSideBar(true);
  }

  const HandlePopup = () => {
    showPopup ? setShowPopup(false) : setShowPopup(true);
    setShowSideBar(false);
   }

return (
    <div className="App">
      
    
        
        
        <SideBarButton HandleSideBar={HandleSideBar} /> <h1 onClick={Home_Button}>VELOX AI</h1>
        {showSideBar ? <SideBar  handleButton={Home_Button} handleLogin={HandlePopup}/> : null}
        {showPopup ? <Popup closeFunction={HandlePopup}/> : null}
       
        {showSearchBar ? 
        <div className="search-bar-container" style={{gridArea: searched ? "sources" : "main",alignItems:  searched ? "start" : "center" , alignSelf: searched ? "start" : "center", marginTop: searched ? "35px" : "none"}} > {/*style={{gridArea: searched ? "sources" : "main"}} */}
          <div className="search-wrap"> {/*, marginTop : searched ? "-755px" : null*/} {/*style={{gridArea: searched ? "sources" : "main"}}*/}
            
            <SearchBar inputValue={inputValue} handleInputChange={handleInputChange} />
            <SearchButton handleButton={handleButton} myImage={myImage}/> 
          </div>  
        </div> : null}
        {responseState ? <HomeButton handleButton={Home_Button}/> : null}
        {responseState ? <SourceOutput sourceNameVar={sourceNameVar} sourceLinkVar={sourceLinkVar} window={window.location.href}/> : null}
        {responseState ? <ResponseOutput formattedText={formattedText}/> : null}
        </div>
  );
}

export default App;








