import '../App.css';
import React from 'react';
import myHomeImage from '../house.png';

export const HomeButton = (props) => {
    return(
        <img className="home-button" src={myHomeImage} alt="home logo" onClick={props.handleButton}/>
    );
}

